@import 'node_modules/nhsuk-frontend/packages/core/all';

.headerButton {
    display: block;
    &:link,
    &:visited {
        color: $color_nhsuk-white;
    }
    &:focus {
        @include nhsuk-focused-button();
    }
}
