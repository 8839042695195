.paging_link {
    border: 0px;
    font-size: 1.1875rem;
    cursor: pointer;
    background-color: transparent;
    color: #005eb8;
    padding: 1px 8px;
    outline: none;
}

.paging_link:hover {
    color: #212b32;
}

.paging_link.disabled {
    color: #999;
}

.paging_link.current {
    color: #212b32;
}

.paging_link:first-child {
    padding-left: 0px;
}
