@import 'node_modules/nhsuk-frontend/packages/core/all';

.Results {
    border: 2px solid $nhsuk-form-border-color;
    background-color: $color_nhsuk-grey-5;
    left: 0;
    list-style-type: none;
    margin-bottom: 0;
    max-height: 252px;
    max-width: 23ex;
    overflow-y: scroll;
    padding: 0;
    position: absolute;
    right: 0;
    z-index: 1;
    margin-top: -26px;
}
