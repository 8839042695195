@import 'node_modules/nhsuk-frontend/packages/core/all';

.ClearButton {
    border: 0;
    bottom: 26px;
    cursor: pointer;
    font-size: 0;
    height: 36px;
    right: 2px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    background-color: $color_nhsuk-grey-5;
}
