@import 'node_modules/nhsuk-frontend/packages/core/all';

.button {
    display: block;
    margin-bottom: 0;
    border-color: $color_nhsuk-grey-5;
    box-shadow: none;
    &::before {
        bottom: 0;
    }
    &:link,
    &:visited {
        color: $color_nhsuk-white;
    }
    &:focus {
        @include nhsuk-focused-button();
    }
}
