.Pagination,
.PaginationResults {
    position: relative;
    top: -24px;
}

.Pagination {
    float: left;
}

.PaginationResults {
    float: right;
}
