@import 'node_modules/nhsuk-frontend/packages/core/all';

.Result {
    border-bottom: 1px solid $nhsuk-border-color;
    margin-bottom: 0;
    position: relative;
}

.ResultItem {
    background-color: $color_nhsuk-grey-5;
    outline: none;
    appearance: none;
    cursor: pointer;
    padding: 10px;
    border: 0;
    text-align: left;
    width: 100%;
    font-size: 16px;
}

.ResultItem .gmc_ref_num {
    float: right;
}

.ResultActive,
.ResultItem:hover {
    background-color: $color_nhsuk-blue !important;
    color: $color_nhsuk-white !important;
}
