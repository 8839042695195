@import 'node_modules/nhsuk-frontend/packages/core/all';

.warningSummary {
  @include nhsuk-responsive-padding(4);
  @include nhsuk-responsive-margin(8, 'bottom');

  border: $nhsuk-border-width-mobile solid $color_nhsuk-warm-yellow;

  @include govuk-media-query($from: tablet) {
    border: $nhsuk-border-width solid $color_nhsuk-warm-yellow;
  }

  &:focus {
    border: $nhsuk-focus-width solid $nhsuk-focus-text-color;
    box-shadow: 0 0 0 $nhsuk-focus-width $nhsuk-focus-color;
    outline: $nhsuk-focus-width solid transparent;
  }
}
.warningSummaryTitle {
  @include nhsuk-font(24, $weight: bold);

  margin-top: 0;
  @include nhsuk-responsive-margin(4, 'bottom');
}
.warningSummaryBody {
  @include nhsuk-font(19);

  p {
    margin-top: 0;
    @include nhsuk-responsive-margin(4, 'bottom');
  }
}
.warningSummaryList {
  @extend %nhsuk-list;
  margin-bottom: 0;
  margin-top: 0;

  a {
    @include nhsuk-typography-weight-bold;

    /* 2 */
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $color_nhsuk-warm-yellow;
    }

    &:focus {
      @include nhsuk-focused-text();
    }
  }

  li {
    @include nhsuk-typography-weight-bold;
    color: $color_nhsuk-warm-yellow;
  }
}
