.filtersHeader {
    background-color: #aeb7bd;
}

.filtersSelected {
    background-color: #d8dde0;
    box-shadow: inset 0 0 0 1px #aeb7bd;
}

.filtersClearFilter {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

.filters {
    box-shadow: inset 0 0 0 1px #aeb7bd;
    margin-top: -2px;
}
