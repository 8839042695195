@import 'node_modules/nhsuk-frontend/packages/core/all';

.SearchWithResults {
    position: relative;
    display: inline-block;
}

.SearchWithResultsInput {
    padding-right: 30px;
}

.SearchWithResultsInput:read-only {
    background-color: $color_nhsuk-grey-5;
}
