.collectionSummaryFilter {
    float: left;
    box-shadow: inset 0 0 0 1px #b1b4b6;
    margin-right: 40px;
    max-width: 385px;
    min-width: 260px;
    width: 20%;
}

.collectionSummaryTable {
    overflow-x: auto;
    transform: translate(10px, 10px);
}
